<!-- main-layout.component.html -->
<div class="pagewrap">
    <nav>
        <app-nav-bar></app-nav-bar>
    </nav>
    <main class="container">
        <router-outlet></router-outlet>
    </main>
    <footer>
        <app-footer></app-footer>
    </footer>
</div>
