import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { GenericService } from '../shared/generic.service';
import { RouterModule } from '@angular/router';



@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  isMobileScreen: boolean = false;
  showMenu: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    private gService:GenericService, private route:RouterModule) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.isMobileScreen = !result.matches;
      this.showMenu = false; // Oculta el menú desplegable cuando cambia el tamaño de la pantalla
    });
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  goToDashboard(){
    this.router.navigate(['/dashboard']);
  }

  goToCompanies() {
    this.router.navigate(['/dashboard']);
  }
  
  goToCatalogs() {
    this.router.navigate(['/dashboard/catalogs']);
  }
  
  goToProfile() {
    this.router.navigate(['/dashboard/profile']);
  }
  logout() {
    this.gService.logoutUser('logout').subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      (error) => {
        console.error('Error during logout:', error);
        // Aquí puedes agregar lógica adicional para manejar el error, como mostrar un mensaje de error al usuario
      }
    );
  }
  
  
  
}
