<div class="navbar">
  <div class="left-items">
    <a href="/dashboard">
      <img src="../../../../assets/img/logo.png" alt="Logo" class="logo-class">
    </a>
  </div>
  <div class="right-items">
    <img src="../../../../assets/img/logo2.png" alt="Otro icono personalizado" class="icon-class">
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu"> <!-- Menú con tres opciones -->
      <button mat-menu-item (click)="goToCompanies()">Compañías</button>
      <button mat-menu-item (click)="goToCatalogs()">Catálogos</button>
      <button mat-menu-item (click)="goToProfile()">Perfil</button>
      <button mat-menu-item (click)="logout()">Cerrar sesión</button>
    </mat-menu>
  </div>
</div>