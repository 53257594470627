import { Component , OnInit } from '@angular/core';
import { ActivatedRoute, Params , Router } from '@angular/router';
import { GenericService } from './../shared/generic.service';
import { Subject , takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-gmailcallback',
  templateUrl: './gmailcallback.component.html',
  styleUrls: ['./gmailcallback.component.scss']
})
export class GmailcallbackComponent implements OnInit{

  constructor(private activatedRoute: ActivatedRoute , private gService: GenericService , private snackBar: MatSnackBar, 
    private router: Router) {}

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];

      this.getGmailCallbackResponse( code );
    
    });

  }

  getGmailCallbackResponse( code:string ) {


    this.gService.get('gmail/callback?code=' + code + "&officeId=" + localStorage.getItem('officeId'),  null )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        
        this.snackBar.open("Gmail conectado correctamente al despacho", 'Cerrar', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
        
        this.router.navigate(['/dashboard']);
        
      },
      (errorResponse: any) => {
        
        this.snackBar.open(errorResponse.error.error, 'Cerrar', {
          duration: 5000,
          panelClass: ['red-snackbar']
        });

        this.router.navigate(['/dashboard']);
      })
  }
}
