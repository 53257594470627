<div class="activity-header">
  <h1>Actividades disponibles</h1>
</div>
<h2 mat-dialog-title>Seleccione las actividades que desea asignar para la cuenta {{ data.name }}</h2>
<div class="activity-search">
  <mat-form-field>
    <mat-label>Buscar...</mat-label>
    <input matInput placeholder="Ex. ium" (keyup)="applyFilter($event.target.value)">
  </mat-form-field>

</div>
<div mat-dialog-content>
  <div *ngFor="let item of filteredActivityList | keyvalue">
    <mat-checkbox [checked]="isSelected(item.key)" (change)="toggleActivity(item, $event.checked)"
      [disabled]="isActivityAssigned(item.key) && !unlockedActivities[item.key]">
      {{item.key}} - {{item.value.value}}
    </mat-checkbox>
    <mat-icon class="activities-lock" *ngIf="isActivityAssigned(item.key)"
      (click)="onLockIconClick(item.key)">{{unlockedActivities[item.key] ? 'check' : 'lock'}}</mat-icon>
  </div>
</div>

<div mat-dialog-actions class="activity-dialog-action">
  <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>