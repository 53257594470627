<!-- Sección para el diálogo de confirmación -->
<div *ngIf="data['type'] === 'confirm'">
  <h1 mat-dialog-title>{{ data['title'] }}</h1>
  <div mat-dialog-content>{{ data['message'] }}</div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancelar</button>
    <button mat-button color="warn" (click)="onConfirm()">Eliminar</button>
  </div>
</div>
<div mat-dialog-actions>
</div>