import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { GenericService } from '../shared/generic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


export const MustMatch: ValidatorFn = (control: FormGroup) => {
  const password = control.get('password');
  const confirmPassword = control.get('passwordConfirmation');

  if (password && confirmPassword && password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ mustMatch: true });
  } else {
    if (confirmPassword) {
      const { mustMatch, ...rest } = confirmPassword.errors || {};
      if (Object.keys(rest).length === 0) {
        confirmPassword.setErrors(null);
      } else {
        confirmPassword.setErrors(rest);
      }
    }
  }
  return null;
};

// Validator for the seller code
export function validateSellerCode(validCodes: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && !validCodes.includes(control.value)) {
      return { 'invalidCode': true };
    }
    return null;
  };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  gmail_email: string;

  // List of valid seller codes
  validSellerCodes = ['code1', 'code2', 'code3', 'code4'];

  constructor(private formBuilder: FormBuilder, private gService: GenericService,
    private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      gmail_email: ['', [Validators.required, Validators.email]],
      office_name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern("^\\d{8}$")]], // Se aceptan exactamente 8 dígitos
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      user_terms_accepted: [false, Validators.requiredTrue],
      seller_code: ['', [Validators.required, validateSellerCode(this.validSellerCodes)]],

    }, { validator: MustMatch });
    this.registerForm.get('office_name').valueChanges.subscribe(value => {
      const cleanedValue = this.getOfficeEmail();
      this.updateEmail(cleanedValue);
    });
  };

  updateEmail(cleanedOfficeName: string) {
    this.registerForm.patchValue({ gmail_email: cleanedOfficeName }, { emitEvent: true });
  }

  getOfficeEmail() {
    let officeName = this.registerForm.get('office_name').value;
    if (officeName) {
      return officeName.replace(/\s/g, '').toLowerCase() + '@cfobots.com';
    } else {
      return '';
    }
  }
  saveUser() {
    if (this.registerForm.valid) {
      this.gService.createUser('users', this.registerForm.value).subscribe(
        response => {
          // Almacena el token y realiza otras acciones necesarias
          const { token, officeId } = response;
          localStorage.setItem('token', token);
          localStorage.setItem('officeId', officeId);

          this.snackBar.open('Usuario creado con éxito', 'Cerrar', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/dashboard']);
        },
        error => {
          console.error('There was an error while creating the user', error);
          // Aquí puedes manejar los errores.
          // Por ejemplo, podrías mostrar un mensaje de error al usuario.
          let errorMessage = 'Hubo un error al crear el usuario';
          if (error.error && error.error.error) {
            // Usa el mensaje de error del servidor si está disponible
            errorMessage = error.error.error;
          }
          this.snackBar.open(errorMessage, 'Cerrar', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
        }
      );
    } else {
      console.error('Form is not valid');
      this.snackBar.open('El formulario no es válido', 'Cerrar', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }
  }


}
