<mat-toolbar class="my-footer">
    <div class="left-items">
        <a href="https://www.advisorycr.com/" target="_blank">
            <img src="./../../../assets/img/logo2.png" alt="Logo" class="logo-class">
        </a>
    </div>
    <div class="middle-items">
        <a href="https://www.facebook.com/CFOBots" target="_blank" class="social-link">
            <i class="social-icon fab fa-facebook-f"></i>
        </a>
        <a href="https://www.cfobots.com/" target="_blank" class="social-link">
            <i class="social-icon fab fa-wordpress"></i>
        </a>
    </div>
    <div class="right-items">
        <span class="copyright">&copy; 2019 - 2023 Cfobots.com</span>
    </div>
</mat-toolbar>
