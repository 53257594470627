import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { GenericService } from '../../shared/generic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { of, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();

  itemsPerPageLabel = 'Elementos por página'; // Cambia el texto aquí

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtro: string = ''; // Variable para almacenar el valor del campo de búsqueda
  datos: any[];
  id: number;
  isLoading = true;


  // Las columnas que se mostrarán en la tabla
  displayedColumns: string[] = ['name', 'description', 'updated_at', 'delete'];
  dataSource = new MatTableDataSource();

  constructor(private paginatorIntl: MatPaginatorIntl, private router: Router,
    private gService: GenericService, private snackBar: MatSnackBar,
    private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private dialog: MatDialog,) {
    this.paginatorIntl.itemsPerPageLabel = 'Elementos por página'; // Cambia el texto aquí también
  }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.getCatalogs();
    this.cdRef.detectChanges();  // Force change detection
  }

  getCatalogs() {
    this.isLoading = true;
    let catalogObservable = this.gService.getCatalogByOfficeId('catalog');

    // For now, we're only using one observable, but you can add more as needed.
    forkJoin([catalogObservable]).subscribe({
      next: (results) => {
        this.datos = results[0].sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
        this.dataSource = new MatTableDataSource(this.datos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.cdRef.detectChanges();  // Force change detection
      },
      error: (error) => {
        console.error('Error al obtener los catálogos:', error.message);
        this.isLoading = false;
        this.cdRef.detectChanges();  // Force change detection
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isOdd(row: any): boolean {
    return this.dataSource.data.indexOf(row) % 2 === 0;
  }

  isEven(row: any): boolean {
    return this.dataSource.data.indexOf(row) % 2 !== 0;
  }

  // Aplica el filtro a tus datos
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
deleteCatalog(id: number, name: string) {
  // Abre el diálogo de confirmación
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    width: '350px',
    data: { 
      type: 'confirm', 
      title: 'Eliminar Catálogo', 
      message: `Estás seguro de que quieres eliminar el catálogo ${name}?` 
    }
  });

  // Maneja la acción del usuario después de cerrar el diálogo
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Si el resultado es 'true', significa que el usuario confirmó la eliminación
      this.isLoading = true;
      this.gService.delete('catalog', id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          this.getCatalogs();
          this.snackBar.open(`El catálogo ${name} ha sido eliminado exitosamente.`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top',
            panelClass:'success-snackbar'
          });
          this.isLoading = false;
          this.cdRef.detectChanges();
        });
    } else {
      // Si el resultado es 'false' o indefinido, el usuario canceló la eliminación
      // No se realiza ninguna acción
    }
  });
}

}