import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const isAuthenticated = this.checkIfUserIsAuthenticated();


    if (!isAuthenticated) {
      console.log('Is authenticated?', isAuthenticated);  // Agrega esta línea
      this.router.navigate(['login']);  // redirige al usuario a la página de inicio de sesión si no está autenticado
      return false;
    }
    return true;
  }

  private checkIfUserIsAuthenticated(): boolean {
    // Aquí es donde verificas si el usuario está autenticado.
    // Esto es solo un ejemplo, y deberás reemplazarlo con tu propia lógica.
    const token = localStorage.getItem('token');
    return !!token;
  }
}
