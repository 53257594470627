import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//angular material



//components
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from "./components/shared/shared.module";
import { RegisterComponent } from './components/register/register.component';
import { VendorComponent } from './components/vendor/vendor.component';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { CatalogsComponent } from './components/dashboard/catalogs/catalogs.component';
import { GmailcallbackComponent } from './components/gmailcallback/gmailcallback.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        VendorComponent,
        MainLayoutComponent,
        CatalogsComponent,
        GmailcallbackComponent,
    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule, 
        SharedModule
    ]
})
export class AppModule { }
