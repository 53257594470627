import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-catalogs-dialog-component',
  templateUrl: './catalogs-dialog-component.component.html',
  styleUrls: ['./catalogs-dialog-component.component.scss']
})
export class CatalogsDialogComponentComponent {
  selectedActivities: any = {}; // Aquí almacenamos las actividades seleccionadas
  isActivityAssigned: Function;
  unlockedActivities: any = {};
  searchValue = '';
  filteredActivityList: any = {};


  constructor(
    public dialogRef: MatDialogRef<CatalogsDialogComponentComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isActivityAssigned = data.isActivityAssigned;

    // Inicializamos selectedActivities con las actividades de la cuenta actual
    Object.keys(data.currentList).forEach(key => {
      this.selectedActivities[key] = data.currentList[key]; // Asignamos el objeto entero, no solo su valor
    });
    this.filteredActivityList = this.data.activityList;

  }



  isSelected(key: string): boolean {
    return !!this.selectedActivities[key];
  }

  toggleActivity(item: any, isChecked: boolean) {
    const key = item.key;
    if (isChecked) {
      this.selectedActivities[key] = item.value; // Almacenamos solo el valor de la actividad
    } else {
      delete this.selectedActivities[key];
    }
  }

  onLockIconClick(key: string) {
    // Asignamos el objeto entero, no solo su valor
    this.selectedActivities[key] = this.data.activityList[key];
    this.data.currentCuenta.list[key] = this.data.activityList[key]; // Asignar la actividad a la cuenta actual

    // Eliminar la actividad de las otras cuentas
    this.data.removeActivityFromOtherCuentas(key, this.data.currentCuenta);

    // Mostrar un snackbar para confirmar que la actividad ha sido reasignada
    this.snackBar.open(`La actividad ${key} ha sido reasignada a ${this.data.currentCuenta.name}`, '', {
      duration: 2000, // duración del snackbar en milisegundos
    });
    this.unlockedActivities[key] = true;
  }
  save(): void {
    this.dialogRef.close(this.selectedActivities);
  }

  //logica para el search:

  applyFilter(filterValue: string) {
    const lowerCaseFilterValue = filterValue.trim().toLowerCase();

    // Asigna un nuevo objeto a filteredActivityList basado en el valor filtrado
    this.filteredActivityList = Object.fromEntries(
      Object.entries(this.data.activityList).filter(([key, value]) =>
        key.toLowerCase().includes(lowerCaseFilterValue) ||
        value['value'].toLowerCase().includes(lowerCaseFilterValue)
      )
    );
  }

  onSearchChange(searchValue: string): void {
    this.searchValue = searchValue;
    this.filterActivityList();
  }

  filterActivityList(): void {
    if (!this.searchValue) {
      this.filteredActivityList = this.data.activityList;
      return;
    }

    this.filteredActivityList = {};

    Object.keys(this.data.activityList).forEach(key => {
      const activity = this.data.activityList[key];
      if (
        key.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        activity.value.toLowerCase().includes(this.searchValue.toLowerCase())
      ) {
        this.filteredActivityList[key] = activity;
      }
    });
  }
//cancelar dialog
  cancel(): void {
    this.dialogRef.close();
  }
}
