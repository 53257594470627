import { Component, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AppComponent {
  title = 'code-web';
  constructor(private router: Router) {}

  isDashboardRoute(): boolean {
    return this.router.url.includes('/dashboard');
  }
}
