<div class="login">
  <div class="container">

    <mat-card class="custom-card">
      <img src="../../../assets/img/3x.png" alt="Descripción de la imagen" class="logo">


      <mat-card-title class="custom-card-title">Iniciar sesión</mat-card-title>

      <mat-card-content>
        <form (submit)="login(email.value, password.value)">
          <mat-form-field class="small-input">
            <mat-label>Correo</mat-label>
            <input matInput #email placeholder="Ingrese su correo electrónico" type="email" required>
          </mat-form-field>

          <mat-form-field class="small-input">
            <mat-label>Contraseña</mat-label>
            <input matInput #password placeholder="Ingrese su contraseña" [type]="hide ? 'password' : 'text'" required>
            <mat-icon matSuffix class="custom-icon" (click)="hide = !hide">
              <i class="fas" [ngClass]="{'fa-eye': hide, 'fa-eye-slash': !hide}"></i>
            </mat-icon>
          </mat-form-field>

          <button mat-raised-button color="primary" style="font-size: 18px;" class="custom-ingresar-button small-button"
            type="submit">Ingresar</button>
        </form>

        <!--  <div class="text-center">
          <p>¿Olvidaste tu contraseña? <a href="#">Recuperar contraseña</a></p>
        </div> -->
      </mat-card-content>

      <mat-card-actions class="create-account-container">
        <div class="create-account">
          <a href="../register">¿No tienes una cuenta? <i class="fas fa-arrow-right"></i></a>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>



</div>