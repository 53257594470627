import { Component } from '@angular/core';
import { GenericService } from '../shared/generic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  hide: boolean = true; // Para el toggle de visibilidad de la contraseña
  loading: boolean = false;
  error: any;
  success:any;
  token:any

  constructor(private gService: GenericService, private snackBar: MatSnackBar, 
    private router: Router) {}

    login(email: string, password: string) {
      this.loading = true;
      this.error = '';
      this.success = '';
    
      this.gService.loginUser('login', { email: email, password: password })
      .subscribe(
        (response: any) => {
          const { user,token, officeId } = response;
    
      

          localStorage.setItem('token', token);
          localStorage.setItem('officeId', officeId);

    
          this.loading = false;
          this.success = 'Ingreso exitoso!';
          this.snackBar.open(this.success, 'Cerrar', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/dashboard']);
    
        },
        (errorResponse: any) => {
          console.error(errorResponse);
          this.loading = false;
          this.error = errorResponse.error.error; // Acceder al mensaje de error proporcionado por el backend
          this.snackBar.open(this.error, 'Cerrar', {
            duration: 5000,
            panelClass: ['red-snackbar']
          });
        }
      );
    
    
      }
}
