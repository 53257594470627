import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  // URL del API, definida en enviroments->enviroment.ts
  urlAPI: string = environment.apiURL;
  // Información usuario actual
  currentUser: any;

  // Inyectar cliente HTTP para las solicitudes al API
  constructor(private http: HttpClient) { }

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('token');

    // Obtener el token de autenticación desde el almacenamiento local
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return headers;
  }

  // Listar
  list(endpoint: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.urlAPI + endpoint, { headers });
  }

  // Obtener
  get(endpoint: string, filtro: any): Observable<any | any[]> {
    const headers = this.getHeaders();

    let url = this.urlAPI + endpoint;

    if (filtro) {
      url = url + `/${filtro}`;
    }
    return this.http.get<any | any[]>(url, { headers });
  }

  // Crear
  create(endpoint: string, objCreate: any | any): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.post<any | any[]>(this.urlAPI + endpoint, objCreate, { headers });
  }

  // Actualizar
  update(endpoint: string, objUpdate: any | any): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.put<any | any[]>(this.urlAPI + endpoint + `/${objUpdate.id}`, objUpdate, { headers });
  }

  //Actualizar catalogo de la compañia
  updateCatalogCompany(endpoint: string, uuid: string, objUpdate: any | any): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.put<any | any[]>(this.urlAPI + endpoint + `/updateCatalog/${uuid}`, objUpdate, { headers });
  }



  // Eliminar
  delete(endpoint: string, id: any): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.delete<any | any[]>(this.urlAPI + endpoint + `/${id}`, { headers });
  }

  // Obtener compañía por UUID
  getCompanyByUuid(endpoint: string, filtro: any): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.get<any | any[]>(this.urlAPI + endpoint + `/${filtro}`, { headers });
  }

  // Obtener compañías por ID de oficina
  getCompaniesByOfficeId(endpoint: string): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.get<any | any[]>(`${this.urlAPI}${endpoint}`, { headers });
  }
  getCatalogByOfficeId(endpoint: string): Observable<any | any[]> {
    const headers = this.getHeaders();
    const officeId = localStorage.getItem('officeId');  // Suponiendo que guardas el officeId en localStorage
    return this.http.get<any | any[]>(`${this.urlAPI}${endpoint}/byoffice/${officeId}`, { headers });
  }
  getCatalogById(endpoint: string, id: number): Observable<any | any[]> {
    const headers = this.getHeaders();
    return this.http.get<any | any[]>(`${this.urlAPI}${endpoint}/byid/${id}`, { headers });
  }

  //logica para cambiar el eba bot y sam bot estado de tracking
  updateBotStatus(botName: string, companyId: number, status: boolean): Observable<any> {
    const headers = this.getHeaders();
    const endpoint = `companies/updateBotStatus/${companyId}`;
    const objUpdate = { botName, status };
    return this.http.put<any>(this.urlAPI + endpoint, objUpdate, { headers });
  }


  // Lógica para el login
  loginUser(endpoint: string, credentials: { email: string, password: string }): Observable<any> {
    return this.http.post<any>(`${this.urlAPI}${endpoint}`, credentials).pipe(
      tap((data) => {
        if (data && data.token && data.officeId) {
          // Almacena el token y el officeId en localStorage cuando lo recibes desde el servidor
          localStorage.setItem('token', data.token);
          localStorage.setItem('officeId', data.officeId);
        }
      })
    );
  }



  // Lógica para el logout
  logoutUser(endpoint: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post<any>(`${this.urlAPI}${endpoint}`, null, { headers }).pipe(
      tap(() => {
        // Elimina el token de localStorage cuando el usuario cierre sesión
        localStorage.removeItem('token');
        localStorage.removeItem('officeId');
      })

    );
  }

  // Crear usuario
  createUser(endpoint: string, objCreate: any | any): Observable<any | any[]> {
    return this.http.post<any | any[]>(this.urlAPI + endpoint, objCreate).pipe(
      tap((data) => {
        if (data && data.token && data.officeId) {
          // Almacena el token y el officeId en localStorage cuando lo recibes desde el servidor
          localStorage.setItem('token', data.token);
          localStorage.setItem('officeId', data.officeId);
        }
      })
    );
  }

  // quickbooks

  // Agrega este método para llamar a getConnectQuickbooks
  getConnectQuickbooks(uuid: string): Observable<any> {
    const headers = this.getHeaders();
    const endpoint = `companies/${uuid}/getConnectQuickbooks`;
    return this.http.get<any>(this.urlAPI + endpoint, { headers });
  }

  currencies(uuid: string): Observable<any> {
    const headers = this.getHeaders();
    const endpoint = `companies/${uuid}/currencies`;
    return this.http.get<any>(this.urlAPI + endpoint, { headers });
  }

  configQB(data: any): Observable<any | any[]> {
    const headers = this.getHeaders();
    const endpoint = `companies/${data.uuid}/configQuickbooks`;
    return this.http.post<any | any[]>(this.urlAPI + endpoint, data, { headers });
  }

  disconnectQuickbooks(uuid: string): Observable<any> {
    const headers = this.getHeaders();
    const endpoint = `companies/${uuid}/disconnectQuickbooks`;
    return this.http.get<any>(this.urlAPI + endpoint, { headers });
  }

  //gmail:

  // Lógica para obtener la URL de conexión a Gmail
  connectGmail(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(`${this.urlAPI}gmail/connect`, { headers });
  }

  // Lógica para desconectar de Gmail
  disconnectGmail(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(`${this.urlAPI}gmail/disconnect`, { headers });
  }

  // Lógica para sincronizar las etiquetas de Gmail
  syncGmailLabels(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(`${this.urlAPI}gmail/syncLabels`, { headers });
  }
  downloadReportEBA(reportType: string, data: { uuid: string, start_date: string, end_date: string }): Observable<Blob> {
    const headers = this.getHeaders();

    const params = new HttpParams()
      .set('start_date', data.start_date)
      .set('end_date', data.end_date);

    const endpoint = `${this.urlAPI}report/${data.uuid}/bill/download${reportType}ReportEBA`;

    return this.http.get<Blob>(endpoint, { headers, params, responseType: 'blob' as 'json' });
  }

  downloadReportSAM(reportType: string, data: { uuid: string, start_date: string, end_date: string }): Observable<Blob> {
    const headers = this.getHeaders();

    const params = new HttpParams()
      .set('start_date', data.start_date)
      .set('end_date', data.end_date);

    const endpoint = `${this.urlAPI}report/${data.uuid}/bill/download${reportType}ReportSAM`;

    return this.http.get<Blob>(endpoint, { headers, params, responseType: 'blob' as 'json' });
  }

}
