import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  vendor = {
    nombre: '',
    apellido: '',
    telefono: '',
    correo: '',
    codigo: this.generateCode()
  };

  successMessage = '';

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    // Aquí puedes realizar cualquier inicialización necesaria para el componente
  }

  submitForm() {
    // Lógica para enviar los datos del vendedor al servidor
    // ...

    const message = `Registro de vendedor exitoso. Su código es ${this.vendor.codigo}`;

    this.snackBar.open(message, 'Cerrar', {
      duration: 300000, // Duración en milisegundos, puedes ajustarla según tus necesidades
      panelClass: ['blue-snackbar'],
      // Clase CSS personalizada para estilo de éxito
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      
    });
  }

  generateCode() {
    // Generar código de 5 caracteres alfanuméricos
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';

    for (let i = 0; i < 5; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return code;
  }
}