<div class="vendor">
  <h2>Registrar vendedor nuevo</h2>

  <mat-card class="card">
    <mat-card-content>
      <form (ngSubmit)="submitForm()" #vendorForm="ngForm" class="form">

        <div class="form-row">
          <mat-form-field class="form-field">
            <input matInput placeholder="Nombre" name="nombre" required [(ngModel)]="vendor.nombre">
            <mat-error *ngIf="vendorForm.controls['nombre'].invalid && vendorForm.controls['nombre'].touched">
              El nombre es requerido
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field">
            <input matInput placeholder="Apellido" name="apellido" required [(ngModel)]="vendor.apellido">
            <mat-error *ngIf="vendorForm.controls['apellido'].invalid && vendorForm.controls['apellido'].touched">
              El apellido es requerido
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field">
            <input matInput type="tel" placeholder="Teléfono" name="telefono" required [(ngModel)]="vendor.telefono">
            <mat-error *ngIf="vendorForm.controls['telefono'].invalid && vendorForm.controls['telefono'].touched">
              El teléfono es requerido
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field">
            <input matInput type="email" placeholder="Correo" name="correo" required email [(ngModel)]="vendor.correo">
            <mat-error *ngIf="vendorForm.controls['correo'].invalid && vendorForm.controls['correo'].touched">
              Por favor, ingresa un correo válido
            </mat-error>
          </mat-form-field>
        </div>

        <input type="hidden" name="codigo" [value]="vendor.codigo">

        <div class="form-row">
          <button mat-raised-button color="primary" type="submit" [disabled]="vendorForm.invalid">
            Registrar
          </button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
