import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

//angular material

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ReactiveFormsModule } from '@angular/forms';


import { FlexLayoutModule } from '@angular/flex-layout';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

//tabs
import { MatTabsModule } from '@angular/material/tabs';

//grid
import { MatGridListModule } from '@angular/material/grid-list';

//datepicker
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

//loader
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//expansion module
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';

//componentes compartidos
import { FooterComponent } from '../footer/footer.component';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CatalogsDialogComponentComponent } from './catalogs-dialog-component/catalogs-dialog-component.component';

@NgModule({
  declarations: [
    FooterComponent,
    NavBarComponent,
    ConfirmDialogComponent,
    CatalogsDialogComponentComponent,

  ],
  imports: [
    HttpClientModule,
    CommonModule,
    //login
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,

    //navbar
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,

    //tabla
    MatTableModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    FormsModule,
    MatPaginatorModule,
    ReactiveFormsModule,

    //mensajes
    MatSnackBarModule,
    MatDialogModule,

    //tabs
    MatTabsModule,
    //grid
    MatGridListModule,

    //fecha datepicker
    MatDatepickerModule,
    MatNativeDateModule,

    //loader
    MatProgressSpinnerModule, 

    //expansion module
    MatExpansionModule,
    MatCheckboxModule

  ],
  exports: [
    HttpClientModule,
    //components
    FooterComponent,
    NavBarComponent,

    //login
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,

    //navbar
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,

    //tabla
    MatTableModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    FormsModule,
    MatPaginatorModule,

    //form
    ReactiveFormsModule,

    //mensajes
    MatSnackBarModule,
    MatDialogModule,

    //tabs
    MatTabsModule,
    //grid
    MatGridListModule,

    //fecha datepicker
    MatDatepickerModule,
    MatNativeDateModule,
    
    //loader
    MatProgressSpinnerModule,

    //expansion module
    MatExpansionModule,
    MatCheckboxModule


  ]
})
export class SharedModule { }
