<div class="container">
    <div class="loading" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
        <img src="../../../../assets/img/logo.png" alt="logo" style="width: 100px;">
    </div>

    <div *ngIf="!isLoading">

    <div class="catalog-header">
        <h1>Lista de catálogos</h1>
        <button mat-button class="add-button" [routerLink]="'create'">
            <mat-icon>add</mat-icon> Agregar catálogo
        </button>
    </div>
    <mat-card class="catalog-table">
        <mat-form-field>
            <mat-label>Buscar...</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
        </mat-form-field>
        <mat-card-content>
            <!-- Vista de tabla para pantallas grandes -->
                <table mat-table [dataSource]="dataSource" matSort class="custom-table mat-elevation-z8">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> 
                            <a [routerLink]="['update', element.id]">{{element.name}}</a> 
                        </td>
                    </ng-container>


                    <!-- Description -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Descripción</th>
                        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                    </ng-container>

                    <!-- last update -->
                    <ng-container matColumnDef="updated_at">
                        <th mat-header-cell *matHeaderCellDef> Actualización </th>
                        <td mat-cell *matCellDef="let element"> {{ element.updated_at | date: 'yyyy-MM-dd' }} </td>
                    </ng-container>

                    <!-- Delete Button -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> 
                            <button mat-icon-button color="warn" class="delete-button" (click)="deleteCatalog(element.id, element.name)"><mat-icon>delete</mat-icon></button> 
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{ 'custom-table-row-odd': isOdd(row), 'custom-table-row-even': isEven(row) }">
                    </tr>

                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25,50, 100]" aria-label="Select page of users"></mat-paginator>

        </mat-card-content>
    </mat-card>
    </div>

</div>
