import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  type?: string;
  title?: string;
  message?: string;
  data?:any;
  allActivities?: any;
  confirmAction?: any;// Agrega la propiedad confirmAction
  isActivityAssigned?:any;
}

interface Objeto {
  key: string;
  value: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Objeto
  ) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
/*   salvarActividad(): void {
    if (this.data.confirmAction) {
      // Crea un nuevo objeto para las actividades marcadas
      const checkedActivities: any = {};
      // Recorre todas las actividades con sus keys
      for (const key in this.data.allActivities) {
        if (this.data.allActivities.hasOwnProperty(key)) {
          const activity = this.data.allActivities[key];
          // Si la actividad está marcada, la agrega al nuevo objeto con su key original
          if (activity.checked) {
            checkedActivities[key] = { ...activity };
          }
        }
      }
     // console.log("checkedActivities:",checkedActivities)
      // Cierra el diálogo y pasa las actividades seleccionadas como resultado
      this.dialogRef.close(checkedActivities);
    } else {
      this.dialogRef.close({});
    }
  } */
  
}
