<nav class="navbar">
    <img class="logo-class" src="../../../assets/img/logo.png" alt="Logo" />
  <div>
    <!-- Aquí puedes agregar más elementos al navbar si lo consideras necesario -->
    <a routerLink="/login" class="icon-class">
      <mat-icon>home</mat-icon>
    </a>
  </div>
</nav>
<div class="register">
  <h1>Registrar cuenta nueva</h1>
  <mat-card class="register-card">
    <form [formGroup]="registerForm" (ngSubmit)="saveUser()">
      <div>
        <p style="font-size: 16px;">Nombre despacho:{{ getOfficeEmail() }}</p>
        <p class="secondary-text">El nombre del despacho se utiliza para crear la cuenta de correo de CFOBots.</p>
        <p class="secondary-text">No debes utilizar tildes ni signos especiales</p>
      </div>
      <br>
      <mat-form-field>
        <mat-label>Nombre Despacho</mat-label>
        <input matInput formControlName="office_name" (input)="getOfficeEmail()">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="first_name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Apellido</mat-label>
        <input matInput formControlName="last_name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Correo</mat-label>
        <input matInput formControlName="email">
        <p class="secondary-text">Correo para inicio de sesión</p>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone" placeholder="88888888">
        <mat-error *ngIf="registerForm.controls['phone'].hasError('pattern')">
          Por favor, introduce exactamente 8 números.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Contraseña</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-error *ngIf="registerForm.controls['password'].hasError('mustMatch')">
          Las contraseñas deben coincidir.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirmación de contraseña</mat-label>
        <input matInput formControlName="password_confirmation" type="password">
        <mat-error *ngIf="registerForm.controls['password_confirmation'].hasError('mustMatch')">
          Las contraseñas deben coincidir.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Código de Vendedor</mat-label>
        <input matInput formControlName="seller_code">
        <mat-error *ngIf="registerForm.controls['seller_code'].hasError('invalidCode')">
          Código de vendedor inválido.
        </mat-error>
        <mat-error *ngIf="registerForm.controls['seller_code'].hasError('required')">
          Este campo es requerido.
        </mat-error>
        <div style="display: flex; align-items: center;" 
             *ngIf="!registerForm.controls['seller_code'].hasError('invalidCode') && !registerForm.controls['seller_code'].hasError('required')">
          <mat-icon *ngIf="registerForm.controls['seller_code'].valid" style="color:green;">check</mat-icon>
          <mat-hint [ngStyle]="{'color': registerForm.controls['seller_code'].valid ? 'green' : 'red'}">
            {{ registerForm.controls['seller_code'].valid ? 'Código de vendedor válido.' : 'Código de vendedor inválido.' }}
          </mat-hint>
        </div>
      </mat-form-field>
      <mat-checkbox formControlName="user_terms_accepted" class="accept-terms">
        Acepto los términos y condiciones
      </mat-checkbox>
      <button mat-raised-button color="primary" [disabled]="!registerForm.valid">Registrar</button>
    </form>
  </mat-card>
</div>
<app-footer></app-footer>